import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useMount, useUnmount, useUpdateEffect } from 'ahooks';
import { useTheme } from 'next-themes';
import { memo, useEffect, useState } from 'react';
import RangeSelector from '../featuresRangeSelector';
import styles from './index.less';
import useApiHooks from './useChartApi';
import useConfigHooks from './useChartConfig';

/**
 TradingView Charting Library
 */
const FeaturesTradingViewChart = function({
                                            chartProperties,
                                            chartType = 1,
                                            className = '',
                                            len = 2,
                                          }: iProps) {
  const { theme } = useTheme();

  const chartBg = theme === 'dark' ? '#1b1b1b' : '#ffffff';

  const [tradingViewWidget, setTradingViewWidget] = useState<any>(null);

  const { changeTime, chartConfig } = useConfigHooks(
    chartProperties?.symbol,
    len,
    chartBg,
  ); // 图的配置hooks

  const datafeed = useApiHooks({
    debug: false,
    chartProperties,
    tradingViewWidget,
  });

  // 图表的属性
  const widgetOptions = {
    datafeed,
    ...chartConfig,
    ...chartProperties,
  };

  const { isMobile } = useModel('system');

  const chartReady = () => {
    if (!tradingViewWidget) return;
    tradingViewWidget.onChartReady(() => {
      const chart = tradingViewWidget.activeChart();
      if (chartType) {
        chart.setChartType(chartType);
      }

      if (isMobile) {
        // 移除当前所有的技术指标
        chart
          .getAllStudies()
          ?.forEach((study: { id: any }) => chart.removeEntity(study.id));
      }

      // 在图表准备好时，订阅时间区间更改事件。
      chart.onIntervalChanged().subscribe(null, function(time: any) {
        changeTime(time);
      });

      chart.priceFormatter().format = function(price: any) {
        return RenderUtil.FormatAmount(price, len, true);
      };
    });
  };

  // socket 相关 市场数据
  const { sendMsg, isSub } = useModel('socket'); // socket 全部的数据
  useEffect(() => {
    // 标准订阅 ，isSub变化后重新订阅
    if ((widgetOptions?.symbol, isSub)) {
      sendMsg(
        `{"type":"SUB_CONTINUE","data": ${JSON.stringify(
          widgetOptions?.symbol,
        )}}`,
      );
    }
  }, [isSub, widgetOptions?.symbol]);

  useMount(() => {
    // 挂载图表

    if (tradingViewWidget) return;

    if (
      !widgetOptions?.symbol ||
      !window.TradingView ||
      !window.TradingView.widget
    ) {
      return;
    }
    try {
      const chartContainer = document.getElementById('chart_container');
      if (!chartContainer) return;

      const widget = new window.TradingView.widget(widgetOptions);
      if (widget) {
        setTradingViewWidget(widget);
      }
    } finally {
    }
  });

  const { setHasReq } = useModel('kline');

  useUnmount(() => {
    // 卸载图表
    if (tradingViewWidget !== null) {
      setHasReq(false);
      tradingViewWidget.remove();
      setTradingViewWidget(null);
    }
  });

  useUpdateEffect(() => {
    if (!tradingViewWidget) return;
    chartReady();
  }, [tradingViewWidget]); // 当 tradingViewWidget 更改时，此 effect 将重新运行

  // 副图的类型，tradingView 的技术指标
  const [subChartType, setSubChart] = useState<
    Array<| 'MACD'
      | 'KDJ'
      | 'RSI'
      | 'Bollinger Bands'
      | 'Volume'
      | 'OBV'
      | 'WR'
      | 'StochRSI'
      | 'MA'
      | 'EMA'
      | 'SAR'
      | 'AVL'
      | ''>
  >([]);

  const switchSubChart = (subChartTypes: string[]) => {
    // 检查 TradingView 图表是否可用
    if (
      !tradingViewWidget?._ready ||
      !tradingViewWidget ||
      !tradingViewWidget.activeChart
    ) {
      return false;
    }

    const chart = tradingViewWidget.activeChart();
    if (!chart) return;

    // 移除当前所有的技术指标
    chart
      .getAllStudies()
      ?.forEach((study: { id: any }) => chart.removeEntity(study.id));

    // 遍历 subChartTypes 数组，添加多个技术指标
    subChartTypes.forEach((subChartType) => {
      switch (subChartType) {
        case 'MACD':
          chart.createStudy('MACD', false, false, [12, 26, 'close', 9]);
          break;
        case 'KDJ':
          chart.createStudy('Stochastic', false, false, [14, 1, 3]);
          break;
        case 'RSI':
          chart.createStudy('Relative Strength Index', false, false, [14]);
          break;
        case 'Bollinger Bands':
          chart.createStudy('Bollinger Bands', false, false, [20, 2]);
          break;
        case 'Volume':
          chart.createStudy('Volume', false, false);
          break;
        case 'OBV':
          chart.createStudy('On Balance Volume', false, false);
          break;
        case 'WR':
          chart.createStudy('Williams %R', false, false, [14]);
          break;
        case 'StochRSI':
          chart.createStudy('Stochastic RSI', false, false, [14, 14, 3, 3]);
          break;
        case 'MA':
          [5, 10, 20, 30, 60, 120, 200].forEach((period) =>
            chart.createStudy('Moving Average', false, false, [period]),
          );
          break;
        case 'EMA':
          [5, 10, 20, 30, 60, 120, 200].forEach((period) =>
            chart.createStudy('Moving Average Exponential', false, false, [
              period,
            ]),
          );
          break;
        case 'SAR':
          chart.createStudy('Parabolic SAR', false, false, [0.02, 0.2]);
          break;
        case 'AVL':
          chart.createStudy('Average Volume', false, false, [20]);
          break;
        default:
          break;
      }
    });
  };
  useEffect(() => {
    if (tradingViewWidget && tradingViewWidget?._ready) {
      // switchSubChart(subChartType);
    }
  }, [subChartType, tradingViewWidget]);

  return (
    <div className="pb-6 h-[100%]">
      <div className={styles[chartConfig.container_id]} id="chart_container" />
      <div className={`${className} pt-1`}>
        <RangeSelector
          value={subChartType}
          onChange={(e: any) => {
            switchSubChart(e);
            setSubChart(e);
          }}
          options={[
            { text: 'MA', value: 'MA' },
            { text: 'EMA', value: 'EMA' },
            { text: 'Boll', value: 'Bollinger Bands' },
            { text: 'SAR', value: 'SAR' },
            { text: 'Volume', value: 'Volume' },
            { text: 'MACD', value: 'MACD' },
            { text: 'RSI', value: 'RSI' },
            { text: 'KDJ', value: 'KDJ' },
            { text: 'OBV', value: 'OBV' },
            { text: 'WR', value: 'WR' },
            { text: 'StochRSI', value: 'StochRSI' },
          ]}
          multiple
        />
      </div>
    </div>
  );
};

export default memo(FeaturesTradingViewChart);

type iProps = {
  key?: any;
  chartProperties: any;
  chartType?: number;
  className?: string;
  len?: number;
};
