import { IcBaselineStar } from '@/assets/icons/comm/IcBaselineStar';
import { MaterialSymbolsStarOutlineRounded } from '@/assets/icons/layout/MaterialSymbolsStarOutlineRounded';
import { CollectEnum } from '@/enums/businessEnum';
import {
  addMemberCollectApi,
  checkCollectApi,
  deleteMemberCollectApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import { Button, cn } from '@nextui-org/react';
import { useMount, useUpdateEffect } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

/**
 * 收藏组件
 */
export default function FeaturesCollect({ collectType }: IProps) {
  const { t } = useTranslation();

  // localFastContract 快速合约
  // localContract 合约
  const { localContract, localFastContract } = useModel('contract');
  const { localCoin } = useModel('coin');
  const { isPc } = useModel('system');
  /**
   * 是否收藏
   */
  const [isCollected, setIsCollected] = useState(false);

  /**
   * 添加收藏
   */
  const { run: runAdd } = useReq(addMemberCollectApi, {
    manual: true,
    onBefore(){
      toast.success(t('收藏成功'));
      setIsCollected(true);
    },
    onError: () => {
      setIsCollected(false);
      toast.error(t('收藏失败,请稍后再试'));
    },
  });

  /**
   * 删除收藏
   */
  const { run: runDelete } = useReq(deleteMemberCollectApi, {
    manual: true,
    onBefore(){
      toast.success(t('收藏成功'));
      setIsCollected(false);
    },
    onError: () => {
      setIsCollected(true);
      toast.error(t('取消收藏失败,请稍后再试'));
    },
  });

  /**
   * 检查币对收藏状态
   */
  const { run: runCheck } = useReq(checkCollectApi, {
    onSuccess: (res: any) => {
      setIsCollected(res as boolean);
    },
  });

  /**
   * 点击收藏按钮
   */
  const onClickCollect = () => {
    if (isCollected) {
      if (+collectType === CollectEnum.FAST_CONTRACT_COLLECT) {
        runDelete({
          ids: localFastContract?.id,
        });
      }
      if (+collectType === CollectEnum.CONTRACT_COLLECT) {
        runDelete({
          ids: localContract?.id,
        });
      }
      if (+collectType === CollectEnum.COIN_COLLECT) {
        runDelete({
          ids: localCoin?.id,
        });
      }
    } else {
      if (+collectType === CollectEnum.FAST_CONTRACT_COLLECT) {
        runAdd({
          sourceId: localFastContract?.id,
          isTripartite: collectType,
        });
      }

      if (+collectType === CollectEnum.CONTRACT_COLLECT) {
        runAdd({
          sourceId: localContract?.id,
          isTripartite: collectType,
        });
      }
      if (+collectType === CollectEnum.COIN_COLLECT) {
        runAdd({
          sourceId: localCoin?.id,
          isTripartite: collectType,
        });
      }
    }
  };
  // 检查是否收藏
  const collectCheck = () => {
    //检查是否收藏
    if (+collectType === CollectEnum.FAST_CONTRACT_COLLECT) {
      runCheck({
        sourceId: localFastContract?.id,
      });
    }

    if (+collectType === CollectEnum.CONTRACT_COLLECT) {
      runCheck({
        sourceId: localContract?.id,
      });
    }

    if (+collectType === CollectEnum.COIN_COLLECT) {
      runCheck({
        sourceId: localCoin?.id,
      });
    }
  };
  // 首次需检查
  useMount(() => {
    collectCheck();
  });
  // 监听币对变化需要检查
  useUpdateEffect(() => {
    collectCheck();
  }, [collectType, localContract, localCoin]);

  if (isPc) {
    return (
      <div
        onClick={(e) => {
          onClickCollect();
        }}
        className=" h-full flex items-center"
      >
        {isCollected ? (
          <IcBaselineStar className="text-primary text-xl" />
        ) : (
          <MaterialSymbolsStarOutlineRounded className="text-xl" />
        )}
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation;
      }}
    >
      <Button
        isIconOnly
        className={cn('', {})}
        radius="full"
        size="sm"
        variant="flat"
        onPress={() => onClickCollect()}
      >
        {isCollected ? (
          <IcBaselineStar className="text-primary text-xl" />
        ) : (
          <MaterialSymbolsStarOutlineRounded className="text-xl" />
        )}
      </Button>
    </div>
  );
}

interface IProps {
  collectType: CollectEnum;
}
