import ProjectSetting from '@/setting/projectSetting';
import { useModel } from '@umijs/max';

interface FormattedKlineData {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

/**
 * 图表的一些常用配置
 */
export default (symbol: any, accuracy = 0, chartBg?: string) => {
  const { setInterval, supported_resolutions } = useModel('kline'); //选中币种的kline list

  // 红涨绿跌设置
  const { 
    upDownColorConfig ,
    klineTimeZone  // 获取k线时区数据
  } = useModel('system');

  /*
          时区改变事件
        */
  const changeTime = async (time: any) => {
    setInterval(time);
  };

  //获取客户端的时区
  const getUserTimeZone = () => {
    // 系统时区
    if(klineTimeZone) return klineTimeZone;
    // 默认展示时区
    if (ProjectSetting.chartTimezone) return ProjectSetting.chartTimezone;
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  //红涨绿跌时候 的配置
  const upRedConfig = {
    'mainSeriesProperties.style': 0,
    // K线图样式
    'mainSeriesProperties.candleStyle.upColor': '#ee4456',
    'mainSeriesProperties.candleStyle.downColor': '#2db880',
    'mainSeriesProperties.candleStyle.drawWick': true,
    'mainSeriesProperties.candleStyle.drawBorder': true,
    'mainSeriesProperties.candleStyle.borderColor': '#ee4456',
    'mainSeriesProperties.candleStyle.borderUpColor': '#ee4456',
    'mainSeriesProperties.candleStyle.borderDownColor': '#2db880',
    'mainSeriesProperties.candleStyle.wickUpColor': '#ee4456',
    'mainSeriesProperties.candleStyle.wickDownColor': '#2db880',
    'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
    // 空心K线图样式
    'mainSeriesProperties.hollowCandleStyle.upColor': '#ee4456',
    'mainSeriesProperties.hollowCandleStyle.downColor': '#2db880',
    'mainSeriesProperties.hollowCandleStyle.drawWick': true,
    'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
    'mainSeriesProperties.hollowCandleStyle.borderColor': '#2db880',
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#2db880',
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#ee4456',
    'mainSeriesProperties.hollowCandleStyle.wickColor': '#737375',
    // 平均K线图样式
    'mainSeriesProperties.haStyle.upColor': '#ee4456',
    'mainSeriesProperties.haStyle.downColor': '#2db880',
    'mainSeriesProperties.haStyle.drawWick': true,
    'mainSeriesProperties.haStyle.drawBorder': true,
    'mainSeriesProperties.haStyle.borderColor': '#2db880',
    'mainSeriesProperties.haStyle.borderUpColor': '#ee4456',
    'mainSeriesProperties.haStyle.borderDownColor': '#2db880',
    'mainSeriesProperties.haStyle.wickColor': '#737375',
    'mainSeriesProperties.haStyle.barColorsOnPrevClose': false,
    // 美国线样式
    'mainSeriesProperties.barStyle.upColor': '#ee4456',
    'mainSeriesProperties.barStyle.downColor': '#2db880',
    'mainSeriesProperties.barStyle.barColorsOnPrevClose': false,
    'mainSeriesProperties.barStyle.dontDrawOpen': false,
  };
  /*
          图表的一些基本配置
        */
  const chartConfig = {
    container_id: 'chart_container',
    // timezone: 'Etc/UTC', // Etc/UTC | getUserTimeZone
    timezone: getUserTimeZone(), // Etc/UTC | getUserTimeZone
    library_path: '/scripts/charting_library/',
    debug: false,
    fullscreen: false,
    allow_symbol_change: true,
    autosize: true,
    autoscale: true, // 启用自动缩放
    disabled_features: [
      //禁用的一些侧边栏
      'header_symbol_search',
      'header_compare',
      'symbol_search_hot_key',
      'compare_symbol',
      'symbol_info',
      'go_to_date',
      'timeframes_toolbar',
      'save_chart_properties_to_local_storage',
      'volume_force_overlay',
      'left_toolbar',
      'header_saveload',
      // 'header_resolutions', //时间选择
      'header_undo_redo', //返回，撤销
      'header_indicators', // 技术指标
      'header_fullscreen_button', //全屏
      'header_screenshot', //保存按钮
      'header_widget',
      upDownColorConfig === 'upGreen' ? '' : 'use_localstorage_for_settings',
    ],
    loading_screen: { backgroundColor: chartBg },
    overrides:
      upDownColorConfig === 'upGreen'
        ? {
            'paneProperties.background': chartBg,
          }
        : {
            'paneProperties.background': chartBg,
            ...upRedConfig,
          },
  };

  // 获取图表小数位数
  function generatePriceScale(specifiedLength = 0) {
    /**
     * specifiedLength 后端指定
     */
    let scale;
    if (!!specifiedLength) {
      scale = Math.pow(10, specifiedLength);
    } else {
      // 没有小数点，scale为1
      scale = 1;
    }
    return scale;
  }

  // 获取图表配置
  const chartReadyConfig = {
    supports_time: true,
    supported_resolutions,
    supports_marks: true,
    supports_timescale_marks: true,
    exchanges: ['MEKE'], //交易所名称
    supports_search: false,
    supports_group_request: false,
  };

  //市场配置
  const resolveSymbolConfig = {
    name: symbol,
    description: '',
    ticker: symbol, //符号的唯一标识符，通常与名称相同。
    listed_exchange: 'Binance12', //符号所在的交易所名称。
    type: 'forex', //符号的类型，例如：股票、外汇等。
    session: '24x7', //交易时段。
    minmov: 1, //最小价格变动。
    pricescale: generatePriceScale(accuracy),
    has_intraday: true, //是否包含日内数据。
    has_daily: true, //是否包含日线数据。
    currency_code: '', //符号相关的货币代码。
    intraday_multipliers: ['1'], //intraday_multipliers
    has_weekly_and_monthly: false, //是否有周线和月线数据。
    has_no_volume: false, //是否没有成交量数据。
    regular_session: '24x7', //常规交易会话时段。
  };

  // 图表准备好的回调
  const resolveSymbol = (
    _symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: (arg0: string) => void,
  ) => {
    setTimeout(() => {
      onSymbolResolvedCallback(resolveSymbolConfig);
    }, 0);
    return;
    onResolveErrorCallback('not found');
  };

  //当图表库准备好接收数据时调用。通常返回一个配置对象，包含图表所支持的功能和设置。
  const onReady = (callback: any) => {
    callback(chartReadyConfig);
  };

  /*
        time: newItem.id * 1000, // 新数据的时间戳
        open: newItem.open, // 开盘价
        high: newItem.high, // 最高价
        low: newItem.low, // 最低价
        close: newItem.close, // 收盘价
        volume: newItem.vol, // 交易量
      */

  //klineData后端返回的 数据
  function formatKlineData(kline: any): FormattedKlineData {
    // 注意这个里面 一定要给默认值 0，不然会有问题
    return {
      time: Number(kline.ts),
      open: parseFloat(kline.o) ?? 0,
      high: parseFloat(kline.h) ?? 0,
      low: parseFloat(kline.l) ?? 0,
      close: parseFloat(kline.c) ?? 0,
      volume: parseFloat(kline?.v ?? 0),
    };
  }

  return {
    changeTime,
    chartConfig,
    onReady,
    formatKlineData,
    resolveSymbol,
  };
};
