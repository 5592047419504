/**
 * @description 从给定的字符串中提取第一个出现的数字。
 * @param resolution 需要解析的字符串，预期包含至少一个数字。
 * @returns 提取的第一个数字，作为整数返回。如果没有找到数字，则返回NaN。
 */
export function extractNumber(resolution: any) {
  return parseInt(resolution?.match?.(/\d+/)[0], 10);
}

/**
 * @description 从给定的字符串中提取单位，并将其标准化为全称。
 * 支持的单位有天（"D", "d"）、周（"W", "w"）、月（"M", "m"），默认单位为分钟。
 * @param resolution 需要解析的字符串，预期包含单位字符。
 * @returns 标准化的单位字符串。如果没有找到匹配的单位，默认返回"minute"。
 */
export function extractUnit(resolution: any) {
  const match = resolution.match(/[a-zA-Z]+/);
  if (!match?.[0]) {
    return 'min'; // 如果没有找到单位，假设它是分钟
  }

  const unit = match?.[0];
  switch (unit) {
    case 'H':
    case 'h':
      return 'hour';
    case 'D':
    case 'd':
      return 'day';
    case 'W':
    case 'w':
      return 'week';
    case 'M':
    case 'm':
      return 'month';
    case 'Y':
    case 'y':
      return 'year';

    default:
      return 'min';
  }
}
