import { extractNumber, extractUnit } from '@/utils/kline';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useState } from 'react';
import useConfigHooks from './useChartConfig';

/**
 * 图表全部的 更新数据的方法
 */
export default ({ chartProperties }: any) => {
  const {
    klineData = [], //图表数据
    setKlineData, //设置图表数据
    loading, //是否在加载
    requestKlinInfo, //请求接口
    interval, //时间分辨率
    LocalChartInfo,
  } = useModel('kline'); //接口 返回的 选中币种的 kline 列表，

  // 配置区域，每一套图表一样的地方 ------------------------
  const key = 'ts'; // 注意这个是后端getKine接口 返回的时间字段,每一个一般不一样，常见 t，id，time
  const chartTitle = chartProperties?.title; //图表左上角的显示币名
  const chartSymbol = chartProperties?.symbol; // 和后端 交互的币id，soket对应取值 & 请求k线的入参
  const priceNowKey = 'a'; //后端soket 返回的当前价格字段，常见 p，a
  const len = chartProperties?.len; //价格保留几位小数

  // socket 相关 市场数据
  const {
    marketList,
    getLastKline,
    lastKlineList,
    getSocketRowByName,
    sendMsg,
  } = useModel('socket'); // socket 全部的数据
  //更新k线方法
  const [realtimeCallback, setRealtimeCallback] = useState<any>(null);
  //图的配置hooks
  const { onReady, resolveSymbol, formatKlineData } = useConfigHooks(
    chartTitle,
    len,
  );

  //一行 soket 推送数据，会动态更新
  const soketData: any = getSocketRowByName(chartSymbol);

  // 增加一个状态来存储时间戳
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const lastKline = getLastKline(chartSymbol);

  // 最新一条的k线数据，接口返回的
  // 这个会更新 图表的最后一条数据
  useUpdateEffect(() => {
    if (lastKline?.s !== chartSymbol) return;
    if (interval !== '1') return;

    if (loading || !marketList?.length || !klineData?.length) return;
    if (lastKline?.[key] && realtimeCallback) {
      if (klineData?.length) {
        const hasTime = klineData?.find(
          (item: any) => item?.[key] === lastKline?.[key],
        );

        const maxTime = klineData[klineData.length - 1]?.[key];
        if (lastKline?.[key] && maxTime && lastKline?.[key] < maxTime) {
          return;
        }

        //新数据时间不存在数据数组里面才会，更新不然会有问题。
        if (hasTime?.[key] === maxTime || !hasTime) {
          realtimeCallback(formatKlineData(lastKline));
          //需要更新kline 数据，不然图会死掉，更新不了当前价格！！！！
          setKlineData((oldArr: any) => {
            return [...oldArr, lastKline];
          });
          const now = new Date().getTime();
          setLastUpdateTime(now);
        }
      }
    }
  }, [lastKline?.[key]]);

  //订阅实时更新。
  const subscribeBars = (
    _symbolInfo: any,
    _resolution: any,
    onRealtimeCallback: any,
    _subscriberUID: any,
    _onResetCacheNeededCallback: any,
  ) => {
    setRealtimeCallback(() => onRealtimeCallback);
  };

  //取消订阅实时更新。
  const unsubscribeBars = (subscriberUID: any) => {
    sendMsg(`{"type":"UNSUB_CONTINUE","data": ${JSON.stringify(chartSymbol)}}`);
  };

  //下面这个方法是请求后端的接口获取图表的数据
  let isOver = false;
  let totalKlines: any = [];
  let lastTime: any = '';
  const getBars = async (
    _symbolInfo: any,
    _resolution: any,
    _from: number,
    _to: number,
    onHistoryCallback: (arg0: never[], arg1: { noData: boolean }) => void,
    _onErrorCallback: any,
    firstDataRequest: any,
  ) => {
    if (lastTime === totalKlines?.[0]?.[key] || isOver) {
      onHistoryCallback([], { noData: true });
      return;
    }

    try {
      if (firstDataRequest || totalKlines?.[0]?.[key]) {
        let reqInterval = interval;
        if (interval === '120') {
          reqInterval = '2H';
        }
        if (interval === '180') {
          reqInterval = '3H';
        }
        if (interval === '240') {
          reqInterval = '4H';
        }
        //----------------请求接口-------------------
        const resData = await requestKlinInfo({
          timeSpan: extractUnit(reqInterval),
          symbol: chartSymbol,
          time: extractNumber(reqInterval),
          endTimestamp: totalKlines?.[0]?.[key] ?? '',
          businessId: LocalChartInfo?.id,
          // exchange: LocalChartInfo?.exchange,
          noMsg: true,
        });

        lastTime = totalKlines?.[0]?.[key] ?? '';
        let data = resData ?? [];
        totalKlines = data.concat(totalKlines);
        totalKlines = totalKlines.sort((a: any, b: any) => a?.[key] - b?.[key]);
        //如果数据低于10条或者没有数据，就返回没有更多
        if (!firstDataRequest && (data?.length === 0 || data?.length < 10)) {
          isOver = true;
        }
      }

      // 空数据返回没有更多数据
      if (totalKlines.length === 0) {
        onHistoryCallback([], { noData: true });
      } else {
        setKlineData(totalKlines);
        const historyCBArray = totalKlines.map((kline: any) =>
          formatKlineData(kline),
        );
        onHistoryCallback(historyCBArray, { noData: false });
      }
    } catch (e) {
    }
  };

  //更新k线 最后的上下波动
  useUpdateEffect(() => {
    if (!(new Date().getTime() - lastUpdateTime > 2000)) return;

    if (
      loading ||
      !marketList?.length ||
      !klineData?.length ||
      soketData.a === 0
    )
      return;
    //最后一条数据
    const lastItem: any = klineData[klineData.length - 1];

    // soket 当前币种的价格
    const nowCoinPrice = soketData?.[priceNowKey];

    if (!nowCoinPrice || !lastItem) return;

    // 对比 soket数据于图表数据，给与波动
    const updatedItem = {
      ...lastItem,
      c: nowCoinPrice,
      h: Math.max(lastItem?.h, nowCoinPrice),
      l: Math.min(lastItem?.l, nowCoinPrice),
    };

    //判断最近两秒是否已经更新过
    if (lastUpdateTime === 0 || new Date().getTime() - lastUpdateTime > 2000) {
      if (realtimeCallback) realtimeCallback(formatKlineData(updatedItem));
      if (nowCoinPrice > lastItem.h || nowCoinPrice < lastItem?.l) {
        setKlineData((oldArr: any) => [...oldArr.slice(0, -1), updatedItem]);
      }
    } else {
      console.log('时间小于两秒暂时不更新');
    }
  }, [soketData, klineData]);

  return {
    onReady,
    resolveSymbol,
    getBars,
    subscribeBars,
    unsubscribeBars,
  };
};
